let _map

const myMap = (map) => {
    if (map) {
        _map = map;
    } 

    return _map;
}

const globalVariables = {
    myMap,
    rideData: {},
    google: {},
    directionsDisplay: {},
    ordersRetrieved: false,
    markerCurrentPosition: {},
    markerFrom: {},
    markerTo: {},
    start: {},
    end: {},
    address: {}
}

export default globalVariables;