import { useLoadScript } from "@react-google-maps/api";
import { useEffect } from "react";
import "@reach/combobox/styles.css";
import globalVariables from "../utils/global-variables";
import './input-style.scss';
import tylko from '../assets/tylko-24.png';
import gwarancja from '../assets/gwarancja.png';

const googleMapsParams = {
    googleMapsApiKey: "AIzaSyBzNNjr0IZ0ty_OZtJKx6hqQ5BvY-CBqkI",
    libraries: ["places"],
    language: 'pl'
}

export default function CustomerMap({ sharedObject }) {
    const { isLoaded } = useLoadScript(googleMapsParams);

    useEffect(() => {
    }, []);

    if (!isLoaded) return <div>Loading...</div>

    globalVariables.google = window.google;
    globalVariables.directionsDisplay = new globalVariables.google.maps.DirectionsRenderer({
        draggable: false,
        suppressMarkers: true,
        preserveViewport: true
    });

    return (
        <>
            <div className="two-boxes image-first">
                <div className="text-box section-24-pln-only">
                    <span>Prosto od producenta<br /> tylko <span className="emphasize">24 zł/kg</span></span>
                </div>
                <img src={tylko} />
            </div>
            <div className="one-box section-quick-contact">
                <span>Szybki kontakt:<br />
                    <span className="emphasize"><a href="tel:+48535121943">+48 535 121 943</a></span><br />
                    Zadzwoń teraz i zapytaj się o dostępność ryb!
                </span>
            </div>
            <div className="two-boxes">
                <div className="text-box section-24-pln-only">
                    <span>Gwaranancja smaku, albo<br /> <span className="emphasize">zadzwoń do nas - sami go zjemy!</span></span>
                </div>
                <img src={gwarancja} />
            </div>
            <div>
                {isLoaded && <Map sharedObject={sharedObject} />}
            </div>
            <div className="one-box">
                <button className='button-59'
                    onClick={() => {
                    }}>
                    Ok
                </button>
            </div>
        </>
    )
}

function Map({ sharedObject }) {
    useEffect(() => {
        initMap();
    }, []);

    const success = async (pos) => {
        let crd;

        if (process.env.NODE_ENV === 'production') {
            crd = pos.coords;
        } else {
            crd = {
                latitude: 52.224526643582585,
                longitude: 20.99026116669797
                // latitude: 52.08935532973885,
                // longitude: 21.03272568214506
            }
        }

        const position = { lat: crd.latitude, lng: crd.longitude }
        globalVariables.myMap().setCenter(position);
        globalVariables.myMap().setZoom(15);

        // globalVariables.start = position;
        globalVariables.markerCurrentPosition.setMap(globalVariables.myMap());
        globalVariables.markerCurrentPosition.setPosition(position);
    }

    const error = (err) => {
        sharedObject.error(`ERROR(${err.code}): ${err.message}`);
        console.warn(`ERROR(${err.code}): ${err.message}`);
    }

    const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    };

    const initMap = async () => {
        const { Map } = await globalVariables.google.maps.importLibrary("maps");
        const position = { lat: 32.245869658521286, lng: 21.107802776763258 }; // gdzies w Libi, zeby pokazac ze mapa sie centruje

        let map = document.getElementById("map");
        let myMap = new Map(map, {
            zoom: 10,
            center: position,
            mapId: "DEMO_MAP_ID",
            zoomControl: true,
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false
        });

        globalVariables.myMap(myMap);

        // navigator.geolocation.getCurrentPosition(success, error, options);
    }

    return <div className={`main`}>
        <div id="map" className={`map-container`}>
        </div>
    </div >
}

