
import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import tylko from '../assets/tylko-24.png';
import gwarancja from '../assets/gwarancja.png';
import globalVariables from "../utils/global-variables";
import { useLoadScript } from "@react-google-maps/api";
import './input-style.scss';
import logo from '../assets/logo32.png';

const googleMapsParams = {
    googleMapsApiKey: "AIzaSyBzNNjr0IZ0ty_OZtJKx6hqQ5BvY-CBqkI",
    libraries: ["places"],
    language: 'pl'
}

const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
};

export default function About() {
    const { isLoaded } = useLoadScript(googleMapsParams);

    useEffect(() => {
    }, []);

    if (!isLoaded) return <div>Loading...</div>

    globalVariables.google = window.google;
    globalVariables.directionsDisplay = new globalVariables.google.maps.DirectionsRenderer({
        draggable: false,
        suppressMarkers: true,
        preserveViewport: true
    });

    const error = () => {

    }

    const success = (pos) => {
        let crd = pos.coords;

        globalVariables.start = { lat: crd.latitude, lng: crd.longitude };
        globalVariables.end = { lat: 50.728119865882995, lng: 18.67156430081874 };
        handleSelect();
    }

    const handleSelect = async () => {
        // var bounds = new globalVariables.google.maps.LatLngBounds();
        // bounds.extend(globalVariables.start);
        // bounds.extend(globalVariables.end);
        // globalVariables.myMap().fitBounds(bounds);
        // var request = {
        //     origin: globalVariables.start,
        //     destination: globalVariables.end,
        //     travelMode: globalVariables.google.maps.TravelMode.DRIVING
        // };

        // globalVariables.markerFrom.setMap(globalVariables.myMap());
        // globalVariables.markerFrom.setPosition(globalVariables.start);
        // globalVariables.markerTo.setMap(globalVariables.myMap());
        // globalVariables.markerTo.setPosition(globalVariables.end);

        // calculateRoute(request);

        var googleLink = `https://www.google.com/maps/dir/${globalVariables.start.lat},${globalVariables.start.lng}/${globalVariables.end.lat},${globalVariables.end.lng}/`;
        window.location.assign(googleLink);
    }

    const calculateRoute = (request) => {
        var directionsService = new globalVariables.google.maps.DirectionsService();

        directionsService.route(request, function (response, status) {
            if (status == globalVariables.google.maps.DirectionsStatus.OK) {
                globalVariables.directionsDisplay.setDirections(response);
                globalVariables.directionsDisplay.setMap(globalVariables.myMap());
                globalVariables.rideData.pickupPosition = { lat: request.origin.lat(), lng: request.origin.lng() };
                globalVariables.rideData.dropPosition = { lat: request.destination.lat(), lng: request.destination.lng() };
            }
        });
    }

    return (
        <>
            <div className="two-boxes section-first">
                <div className="text-box section-24-pln-only">
                    <span>Prosto od producenta<br /> tylko <span className="emphasize">24 zł/kg</span> karpia</span>
                </div>
                <img src={tylko} />
            </div>
            <div className="one-box section-quick-contact">
                <span>Szybki kontakt:<br />
                    <span className="emphasize"><a href="tel:+48604057026">+48 604 057 026</a></span><br />
                    Zadzwoń teraz i zapytaj się o dostępność ryb!
                </span>
            </div>
            <div className="two-boxes">
                <div className="text-box section-24-pln-only">
                    <span>Gwarancja smaku, albo<br /> <span className="emphasize">zadzwoń do nas - sami go zjemy!</span></span>
                </div>
                <img src={gwarancja} />
            </div>
            <div className="one-box section-quick-contact">
                <span>Na życzenie patroszymy i filetujemy zakupione ryby!
                </span>
            </div>
            <div>
                {isLoaded && <Map />}
            </div>
            <div className="one-box">
                <button className='button-59'
                    onClick={() => {
                        navigator.geolocation.getCurrentPosition(success, error, options);
                    }}>
                    Nawiguj mnie po najlepszego karpia w regionie!
                </button>
            </div>
        </>
    );
};

function Map({ sharedObject }) {
    useEffect(() => {
        initMap();
    }, []);

    const initMap = async () => {
        const { Map } = await globalVariables.google.maps.importLibrary("maps");
        const position = { lat: 50.728119865882995, lng: 18.67156430081874 };
        const center = { lat: 50.51363923838176, lng: 18.562139153500662 }

        let map = document.getElementById("map");
        let myMap = new Map(map, {
            zoom: 8.5,
            center: center,
            mapId: "DEMO_MAP_ID",
            zoomControl: true,
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false
        });

        globalVariables.myMap(myMap);

        globalVariables.markerFrom = new globalVariables.google.maps.Marker({
            draggable: false
        });
        globalVariables.markerTo = new globalVariables.google.maps.Marker({
            draggable: false,
            icon: logo
        });
        globalVariables.markerTo.setMap(globalVariables.myMap());
        globalVariables.markerTo.setPosition(position);

        // navigator.geolocation.getCurrentPosition(success, error, options);
    }

    return <div className={`main`}>
        <div id="map" className={`map-container`}>
        </div>
    </div >
}
