
import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate }
    from 'react-router-dom';
import About from './pages/About';
import './styles.scss';
import './loader.scss';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Loader from './pages/Loader';
import logo from './assets/logo.png';
import headline from './assets/headline.png';
import CustomerMap from './tigi/CustomerMap';

function App() {
    const navigate = useNavigate();

    const [showLoader, setShowLoader] = useState(false);
    const [sharedObject, setSharedObject] = useState({
        loading: false,
        setShowLoader,
        error: (message, errorObj) => {
            NotificationManager.error(message, 'Error', 20000);
            NotificationManager.error(errorObj, 'Error', 20000);
            console.error(message, errorObj);
        },
        success: (message) => {
            NotificationManager.success(message, 'Success', 3000);
        }
    });

    useEffect(() => {
    }, []);


    const redirectToHome = () => {
        navigate('/');
    }

    return (
        <>
            <header className='main-element'>
                <img src={logo} onClick={() => redirectToHome()} />
                <img src={headline} />
                <img src={logo} onClick={() => redirectToHome()} />      
            </header>
            <NotificationContainer />
            {showLoader &&
                <Loader />
            }
            <div className='main-element'>
                <Routes>
                    <Route path='/' element={<About />} />     
                    <Route path='/customer' element={<CustomerMap />} />               
                </Routes>
            </div>
            <footer className='main-element'>Biały Ług 1a, 42-713 Kochcice</footer>
        </>
    );
}

export default App;